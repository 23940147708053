import { Link } from "gatsby"
import * as React from "react"

const Footer = () => {

  return (
    <footer className="flex justify-center font-light text-xs items-center w-full bg-black tracking-wider" style={{minHeight: '3rem', maxHeight: '3rem'}}>
        <Link to="https://bitbacon.gr" target="_blank" rel="noopener noreferrer" className="text-stone-400">BitBacon © {new Date().getFullYear()}</Link>
    </footer>
  )
}

export default Footer