import React from 'react';
import PropTypes from "prop-types"
import Navbar from "./navbar";
import Footer from './footer';

const Layout = ({ children }) => {

  return (
    <div className="flex flex-col" style={{ minHeight: '100dvh' }}>
      <Navbar />
      <main>
        <div>{children}</div>
      </main>
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
