import React, { useState } from "react"
import { Link } from "gatsby"
import './navbar.css';

const Navbar = () => {

    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <header >
            <nav className="bg-blue-400 flex w-full" style={{ minHeight: '5rem', maxHeight: '5rem' }}>
                <div className="pr-4 pl-4 bg-black flex justify-between items-center grow">
                    <Link to="/" className="flex flex-col">
                        <span className="text-white text-3xl font-semibold tracking-wider">GBSF</span>
                        <span className="text-xs font-extralight tracking-wider text-teal-400"
                            style={{ paddingLeft: '3px' }}>Greek Black Soldier Fly</span>
                    </Link>

                    <button aria-label="burger-btn" className={`burger-button ${isOpen ? 'open' : ''}`} onClick={toggleMenu} >
                        <div className="bar"></div>
                        <div className="bar"></div>
                        <div className="bar"></div>
                        <div className={`menu ${isOpen ? 'open' : ''}`}>
                            <ul className="text-white text-lg">
                                <Link to="/" activeClassName="bg-white bg-opacity-20">
                                    <li className="bg-inherit">Home</li>
                                </Link>
                                <Link to="/shop" activeClassName="bg-white bg-opacity-20">
                                    <li className="bg-inherit">Shop</li>
                                </Link>
                                <Link to="/about" activeClassName="bg-white bg-opacity-20">
                                    <li className="bg-inherit">About</li>
                                </Link>
                                <Link to="/contact" activeClassName="bg-white bg-opacity-20">
                                    <li className="bg-inherit">Contact</li>
                                </Link>
                            </ul>
                        </div>
                    </button>
                </div>
            </nav>
        </header>
    )
}

export default Navbar
