module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/opt/build/repo/src/components/layout/layout.jsx"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Greek Black Soldier Fly Official Site","short_name":"GBSF","start_url":"/","background_color":"#ffffff","theme_color":"#000000","display":"standalone","icon":"./src/static/images/favicon.png","icons":[{"src":"./src/static/images/favicon-192.png","sizes":"192x192","type":"image/png"},{"src":"./src/static/images/favicon-512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d8fca61e2bb8be2e21b0096681d37704"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
